.about-us-wrapper{
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.about-hero{
    display: flex;
    flex-direction: "column";
    width: 100%;
    height: 600px;
    background-color: #040404;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;

}

.hero-background{
    width: 501px;
height: 433px;
flex-shrink: 0;
border-radius: 500px;
opacity: 0.23;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
filter: blur(100px);
}
