.magnetLines-container {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: repeat(var(--rows), 1fr);
  
    justify-items: center;
    align-items: center;
  
    width: 80vmin;
    height: 80vmin;
  }
  
  .magnetLines-container span {
    display: block;
    transform-origin: center;
    will-change: transform;
    transform: rotate(var(--rotate));
  }