@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");

.variable-proximity {
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 800;
    line-height: normal;
    background: linear-gradient(135deg, #FFF 30%, #D5D8F6 80%, #FDF7FE 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
