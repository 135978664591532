/* DataTable.css */
.data-table-container {
  width:100%;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  border-radius: 6px 6px 16px 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: #010C12;
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
}

.data-table-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.data-table-filters {
  display: flex;
  justify-content: start;
  align-items: center;
}
.data-table-filters p{
  color: var(--Neutral-100, #FFF);
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.5px;
  margin-right: 10px;
}
.data-table-filters .filter-input{
  width: 250px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #191C20;
  border: none;
  color: #8E8C91;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.data-table-filters .filter-input::placeholder{
  color: #8E8C91;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16px;
padding-left: 15px;
}

.data-table-header {
  color: var(--Neutral-100, #FFF);
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -1px;
}

.data-table-button {
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 104.55px;
  height: 34px;
  padding: 6.8px 12.75px;
  gap: 1.7px;
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
  border: none;
  color: #000;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;

}

.data-table-button:hover {
  background-color: #1565c0;
}

.data-table-input {
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.table-container{
  width: 100%;
  max-height: 350px;
  overflow-x: auto;
}

.table-overflow{
  overflow-x: auto;
}

table {
  width: 100%;
  border-radius: 15px;
  margin-top: -220px;
  margin-bottom: 20px;
  border-radius: 40px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
}

table thead{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;

}
.table thead th:first-child {
  border-top-left-radius: 10px;

}
.table thead th:last-child {
  border-top-right-radius: 10px;
}

thead {
background: #2A2C30;
position: sticky;
top: 0;
z-index: 2;
}

th {
padding: 15px 2px 15px 2px;
text-align: center;
color: #B8B8B8;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
letter-spacing: -0.6px;
}

td {
  padding: 10px;
}

tr{
color: var(--Neutral-100, #FFF);
font-family: "Plus Jakarta Sans";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 180% */
letter-spacing: 0.15px;
text-align: center;
}

tr:hover {
  scale: 1.02;
}

.data-table-row-dark {
  border: 1px solid var(--Noble-Black-500, #363A3D);
  background: #020D13;
}
.data-table-row-grey {
  border: 1px solid #363A3D;
  background: #1d1f23;
}

.data-table-edit-row{
  background: #999B9D;
  border: 1px solid var(--Noble-Black-500, #363A3D);
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.pagination button {
  background-color: #020D13;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border: 1px solid grey;
}

.pagination button:hover {
  background-color: #1565c0;
}

.pagination button:disabled {
  background-color: #363A3D;
  cursor: not-allowed;
}

.pagination input {
  width: 50px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.pagination select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-btn ,
.delete-btn {
  width: 28px;
height: 28px;
flex-shrink: 0;
border-radius: 50px;
border: 1px solid #363A3D;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
cursor: pointer;
}

.edit-btn{
  margin-right: 4px;
}

.edit-btn img{
  width: 12px;
height: 12px;
}

.edit-save-btn{
  width: 23px;
height: 23px;
background-color: #01FF85;
border-radius: 50px;
border: none;
margin-right: 6px;
}

.delete-btn img{
  width: 12px;
height: 12px;
}

.download-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.download-icon:hover {
  opacity: 0.8;
}

.edit-input{
  width:84px;
  background:#D9D9D9;
  color: #010C12;
font-family: "Plus Jakarta Sans";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 180% */
letter-spacing: 0.15px;
border: none;
padding-left: 10px;
}