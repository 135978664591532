.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    z-index: 1000;
  }
  
  .modal-content-new {
    width: 100%;
    max-width: 800px;
    background: #010C12;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
    padding: 15px 22px 15px 22px;
    color: var(--Neutral-100, #FFF);
    border: 1px solid var(--Noble-Black-500, #363A3D);
    z-index: 99;
  }
  .modal-content {
    width: 100%;
    max-width: 700px;
    background: #010C12;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
    padding: 15px 22px 15px 22px;
    color: var(--Neutral-100, #FFF);
    border: 1px solid var(--Noble-Black-500, #363A3D);
  }

  .close-btn{
    width: 120px;
height: 36px;
flex-shrink: 0;
border-radius: 12px;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
align-self: right;
color: #000;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
letter-spacing: 0.15px;
  }

  .dont-show-again-checkbox-text{
   color:#727070;
   align-self: center;
  }
  .dont-show-again-checkbox{
    width: 15px;
    height: 15px;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 8px;
  }

