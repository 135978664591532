/* Avatar.css */
.avatar-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    z-index: 11;
  }
  
  .speaking {
    animation: speakingAnimation 2s infinite;
  }
  
  @keyframes speakingAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }