#root{
  height: 100%;
}

.App {
  font-family: "Plus Jakarta Sans", sans-serif;
  background-color: #010C12;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 100vh; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.avatar {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.task-list{
  margin-top: 20px;
  height: 510px;
}

.data-analysis {
  margin-top: 20px;
  height: auto;
  height: 100%;
}

h3 {
  margin-top: 0;
}


/* Global styles for both vertical and horizontal scrollbars */
::-webkit-scrollbar {
 width: 0px; 
 height: 0px;
}

/* Track styles */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Thumb styles */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
