.landing-wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
  overflow: hidden;
  max-width: 1440px;
  margin:0 auto;
}

.hero-img img{
  width: 715.734px;
  height: 726.38px;
}

.background-header {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.landing-wrapper .background-image {
  width: 60%;
}

.landing-header {
  margin-bottom: 27px;
  margin-top: 100px;
}

.action-btn-wrapper-landing {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
}

.btn-main {
  display: inline-flex;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.btn-secondary {
  display: inline-flex;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
}



.backdrop {
  position: absolute;
  width: 501px;
  height: 433px;
  flex-shrink: 0;
  border-radius: 500px;
  opacity: 0.23;
  background: linear-gradient(91deg, #00ff85 7.38%, #00e5ff 96.85%);
  filter: blur(100px);
  top: -190px;
  left: 33%;
}

.text-md {
  width: 1055px;
  color: #868686;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 50px;
}

.datalyse-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
}

.datalyse-info-wrapper-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
}

.datalyse-info-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.datalyse-info-item{
  width:360px;
}

.datalyse-info-container .datalyse-info-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 2rem;
}

.datalyse-info-container .datalyse-info-item .header {
  color: #868686;
  font-family: "Plus Jakarta Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 17px;
}

.datalyse-info-container .datalyse-info-item .text-para,
.learning-path-container .learning-path-item .text-para-md {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.datalyse-info-img2{
  padding-left: 10px;
}

.learning-path-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 45px;
  padding-right: 45px;
}

.learning-path-item {
  width: 434px;
  height: 91px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #a2a4a7;
  border-bottom: none;
  background: linear-gradient(180deg, #1f1f24 -32.77%, #040404 91.6%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px 0px 25px;
  margin-right: 15px;
  margin-bottom: 25px;
}

.learning-path-container .learning-path-item .text-para-md {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.features-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
}

.features-item {
  width: 320px;
  height: 317px;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(
    178deg,
    rgba(35, 31, 32, 0.23) 10.33%,
    /* Opacity for black */ rgba(0, 254, 137, 0.23) 106.1%,
    /* Opacity for green */ rgba(0, 229, 255, 0.23) 276.34%
      /* Opacity for blue */
  );
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 35px;
  margin-right: 35px;
}

.features-item .feature-img {
  width: 121px;
  height: 121px;
  flex-shrink: 0;
}
.features-item .feature-title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}
.features-item .feature-para {
  color: #868686;
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 13px;
}

.hero-section {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hero-section .hero-section-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* position: absolute;
  top: 60%;
  left: 0; */
}

.footer-section-bg {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Allow the height to adjust based on content or background image */
  border-top: 1px solid #a2a4a7;
  margin-top: 160px;
  position: relative;
  background: linear-gradient(180deg, #1f1f24 -32.77%, #040404 91.6%);
}
.footer-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #1f1f24 -32.77%, #040404 91.6%);
  background-image: url("../assets/img/footer-bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer-primary {
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: linear-gradient(91deg, #e3e3e3 14.1%, #5f6563 96.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 130px;
}
.footer-secondary {
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(91deg, #01fe87 7.61%, #01e6f9 76.3%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 16px;
}

.header-section-bg {
  display: flex;
  flex-direction: column;
  height: 500px; /* Allow the height to adjust based on content or background image */
  position: relative;
}

.header-section {
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #1f1f24 -32.77%, #040404 91.6%);
  background-image: url("../assets/img/header-bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.primary-button-main{
  display: inline-flex;
  width: 240px;
  padding-top: 17.391px;
  padding-bottom: 17.391px;
  justify-content: center;
  align-items: center;
  gap: 10.87px;
  border-radius: 4px;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
color: #000;
font-family: "Plus Jakarta Sans";
font-size: 15.217px;
font-style: normal;
font-weight: 700;
line-height: 100%;
cursor: pointer;
border: none;
}


@media screen and (max-width: 320px) {
.hero-img img{
  width:350px;
  height: auto;
}
}

@media screen and (max-width: 425px) {
  .hero-img img{
    width:400px;
    height: auto;
  }
  .line-div{
    margin-top: 80px;
  }
  }

@media screen and (max-width: 768px) {
  .datalyse-info-wrapper {
    flex-direction: column-reverse;
  }

  .datalyse-info-wrapper-2 {
    flex-direction: column;
  }

  .datalyse-info-img2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    padding-left: 0;
    margin-right: 0;
  }

  .datalyse-info-content {
    flex-basis: 100%;
  }

  .datalyse-info-container {
    margin-bottom: 20px;
  }

  .datalyse-info-item{
    padding: 1rem;
    margin-right: 0px;
  }

  .datalyse-info-img {
    flex-basis: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .datalyse-info-item{
   margin-right: 0px;
  }
  .datalyse-info-img2 img{
    margin-right: 0px;
    padding-left: 20px;
  }
 }

 @media screen and (min-width: 1025px) {
  .datalyse-info-item {
    margin-right: 20px;
  }
  .datalyse-info-img2  {
    margin-right: 30px;
    padding-left: 180px;
  }
}
