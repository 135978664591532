html {
  background: #f2f2f2;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-padding-top: 10px; /* Ensures correct snapping */
  scroll-behavior: smooth; /* Enables smooth scrolling */
  height: 400px; /* Ensure proper scrolling space */
  width: 100%;
}

.scroll-area {
  scroll-snap-align: start;
  scroll-snap-stop: always; /* Forces snap to happen */
  min-height: 100%; /* Ensure full-screen snap effect */
}
.scroll-container,
.scroll-area {
  margin: 0 auto;
}

.scroll-area {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.infinite-scroll-item {
  box-sizing: border-box;
  position: relative;
  width: 420px; /* Default width */
  height: 360px; /* Default height */
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #D9D9D9;
  background: rgba(255, 255, 255, 0.04);
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 25px;
  
}

.scroll-item-header{
  font-family: "Plus Jakarta Sans";
font-size: 34px;
font-style: normal;
font-weight: var(--font-weight-700, 700);
line-height: 34px; /* 100% */
letter-spacing: -1px;
background: linear-gradient(135deg, #FFF 30%, #D5D8F6 80%, #FDF7FE 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.scroll-item-description{
  color: #868686;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 136.364% */
letter-spacing: -0.5px;
margin-top: 20px;
}
