@media (max-width: 768px) {
    .menu-text {
      display: none;
    }
    .menu-image {
      display: none;
    }
    .menu-card {
      justify-content: center;
      align-items: center;
    }
    .home-image {
      display: none;
    }
    .redeem-box {
      display: none;
    }
    .total {
      display: none;
    }
    .coins-text {
      display: none;
    }
  }
  
  @media (max-width: 1024px) {
    .home-image {
      display: none;
    }
  }
  