.text-uppercase {
  color: #fff;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 15px;
}

.column-card {
  width: 188px;
  height: 45px;
  background-color: #00ff85;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 7px;
  position: relative;
  margin-bottom: 5px;
  opacity: 1; 

}

.drag-animation{
  animation: shake 0.5s infinite;
}

.columnName {
  color: #010c12;
  font-family: "Plus Jakarta Sans";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.5px;
  word-spacing: 3px;
}

.dataType {
  position: absolute;
  right: 10px;
}
.sticky-top {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
}

.droppable-field {
  width: 260px;
  height: auto;
  border: 0.5px solid var(--Noble-Black-500, #363a3d);
  border-radius: 3px;
  margin-right: 10px;
  background: #020d13;
  display: flex;
  flex-direction: column;
}

.droppable-field-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
  width: 100%;
}

.dataType-left{
  position: absolute;
  left: 5px;
  top: 5px;
}

.droppable-field-header .header-text {
  color: #fff;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.5px;
}

.dropped-item-container {
  position: relative;
  min-height: 52px;
  border: 1px dashed rgba(54, 58, 61, 0.4);
  background: rgba(0, 255, 133, 0.7);
  margin: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.5px;
}

.drop-text-container {
  min-height: 52px;
  border: 1px dashed rgba(54, 58, 61, 0.4);
  background: #191c20;
  margin: 6px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.drop-text {
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.5px;
}

.raw-dropdown {
  width: 200px;
}

.dropdown-toggle {
  width: 85px;
  height: 28px;
  text-align: left;
  height: 100%;
}

.dropdown-toggle::after {
  right: 8px;
  bottom: calc(50% - 1px);
  position: absolute;
}

.dropdown-primary {
  width: 85px;
  height: 28px;
  border-radius: 4px;
  background: rgba(0, 255, 133, 0.2);
  border: none;
  margin-left: 20px;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.5px;
}
.dropdown-danger {
  width: 85px;
  height: 28px;
  border-radius: 4px;
  background: rgba(172, 50, 37, 0.865);
  border: none;
  margin-left: 20px;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.5px;
}

@keyframes shake {
  0% {
    transform: translate(0, 0); /* Original position */
  }
  25% {
    transform: translate(6px, 0); /* Move left */
  }
  50% {
    transform: translate(10px, 0); /* Move right */
  }
  75% {
    transform: translate(-6px, 0); /* Move left again */
  }
  100% {
    transform: translate(0, 0); /* Back to original position */
  }
}