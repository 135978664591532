.contact-us-wrapper{
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.contact-hero{
    display: flex;
    flex-direction: "column";
    width: 100%;
    height: 600px;
    background-color: #040404;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center; 
}

.hero-background{
    width: 501px;
height: 433px;
flex-shrink: 0;border-radius: 500px;
opacity: 0.23;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
filter: blur(100px);
z-index:1;
position: absolute;
}

.contact-us-description{
    color: #868686;
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 40px;
}

.contact-main-section{
    height: 762px;
    background: linear-gradient(180deg, #1F1F24 -32.77%, #040404 91.6%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 50px;
}

.assistance-contianer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 80px;
}

.assistance-item{
    width: 426px;
    height: 401px;
    flex-shrink: 0;
    border-radius: 20px;
border: 1px solid #D9D9D9;
display: flex;
flex-direction: column;
padding: 20px;
position: relative;
}

.assistance-title{
    font-family: "Plus Jakarta Sans";
font-size: 32px;
font-style: normal;
font-weight: var(--font-weight-700, 700);
line-height: 34px; /* 106.25% */
letter-spacing: -1px;
background: linear-gradient(135deg, #FFF 30%, #D5D8F6 80%, #FDF7FE 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.assistance-description{
color: #868686;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 136.364% */
letter-spacing: -0.5px;
margin-top: 25px;
}

.assistance-img-wrapper{
    position: absolute;
    left: 0;
    bottom: 0;
width: 100%;
height: auto;
display: flex;
flex-direction: row;
justify-content: center;
padding-bottom: 20px;
}
.assistance-img-wrapper .assistance-img{
    width: 362px;
height: 197px;

}

.contact-details-section{
width: 100%;
height: auto;
flex-shrink: 0;
background-color: #040404;
display: flex;
flex-direction: column;
align-items: center;
justify-content: start;
}

.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.formContainer .input-styles{
    width: 300px;
    height: 56px;
border-radius: 5px;
border: 1px solid var(--color-grey-30, #4A4B50);
background: var(--huly-io-black, #000);
}


.formContainer .input-styles{
    width: 300px;
    height: 56px;
border-radius: 5px;
border: 1px solid var(--color-grey-30, #4A4B50);
background: var(--huly-io-black, #000);
color: #fff;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 28px; 
padding: 10px 15px;

}

.formContainer .input-styles:focus {
    border-color: var(--color-primary, #4caf50); /* Change this to your desired focus color */
    outline: none; /* Removes the default outline */
}

.formContainer .input-styles::placeholder {
    color: #D2D2D2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 

}

.formContainer .submit-btn{
    display: inline-flex;
    width: 120px;
height: 52px;
padding: 15.294px 22.941px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
flex-shrink: 0;
border-radius: 7.647px;
border: 1.529px solid var(--huly-io-turquoise-blue, #55E7B2);
background: var(--huly-io-woodsmoke, #0B0C0F);
color: #FFF;
text-align: center;
font-size: 21.412px;
font-style: normal;
font-weight: 400;
line-height: 42.824px; /* 200% */
}

.contact-footer-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
height: 500px;
background-color: #040404;
}

.contact-footer-bg{
    position: absolute;
    left: 0px;
    top:-130px;
width: 100%;
z-index: 1;
}

.social-media-btn{
    display: flex;
width: 313px;
height: 74px;
padding: 15px 0px;
justify-content: center;
align-items: center;
gap: 2px;
flex-shrink: 0;
border-radius: 37.037px;
border: 0.741px solid #868686;
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
background: linear-gradient(91deg, #01FE87 7.61%, #01E6F9 76.3%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
z-index: 100;
/* margin-top: 60px; */
}

.social-media-icon-container{
    display: flex;
    flex-direction: row;
    z-index: 100;
    margin-top: 40px;
}