.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  height: auto;
  padding: 40px;
  padding-bottom: 130px;
}

.wrapper .pulsating-text {
  font-size: 28px;
  font-weight: 600;
  color: #a9b7c6; /* Softer shade of light blue */
  animation: pulse 3s infinite;
  text-shadow: 0 0 3px #a9b7c6, 0 0 5px #a9b7c6, 0 0 8px #5f7a8a;
}
.home-container{
  width: 100%;
  height: 65px;
  border-radius: 8px;
border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
opacity: 0.4;
background: #0A1218;
box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
margin-bottom: 10px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding-left: 15px;
padding-right: 15px;
}

.datalyze-img{
  width: 153px;
height: 30.48px;
flex-shrink: 0;
}

.exl-img {
  width: 100px;
  max-width: 100px;
  height: 20.48px;
  max-height: 20.48px; /* Ensure height stays consistent */
  flex-shrink: 0;
  object-fit: contain; /* Prevents distortion */
}


.progress-holder {
  border-radius: 15px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: var(--Glass-Modal, rgba(26, 29, 33, 0.46));
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  display: flex;
  flex-direction: column;
  padding: 17px;
  margin-right: 30px;
  height: 100%;
  position: relative;
}

.burger-menu-expanded,
.burger-menu-collapsed {
  transition: transform 0.3s ease, filter 0.3s ease;
}

.burger-menu-expanded:hover,
.burger-menu-collapsed:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.task-text {
  font-size: 12px;
  color: var(--Noble-Black-400, #686b6e);
  font-weight: 600;
  letter-spacing: 0.15px;
  margin-bottom: 20px;
}
.taskDetailItemExpanded {
  display: flex;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: linear-gradient(
    118deg,
    rgba(215, 237, 237, 0.16) -47.79%,
    rgba(204, 235, 235, 0)
  );
  margin-bottom: 10px;
}

.taskDetailItemUnexpanded {
  display: flex;
  align-items: center;
  padding: 15px;
  height: 44px;
  border-radius: 8px;
  margin-bottom: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    text-shadow: 0 0 3px #a9b7c6, 0 0 5px #a9b7c6, 0 0 8px #5f7a8a;
  }
  50% {
    transform: scale(1.03);
    text-shadow: 0 0 5px #a9b7c6, 0 0 8px #5f7a8a, 0 0 10px #708b9e; /* Muted blue-gray */
  }
  100% {
    transform: scale(1);
    text-shadow: 0 0 3px #a9b7c6, 0 0 5px #a9b7c6, 0 0 8px #5f7a8a;
  }
}

.coins-tooltip {
  width: 100%;
  height: 75px;
  border-radius: 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.04));
  background-color: rgba(26, 29, 33, 0.4);
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.no-duplicate-dataset-toast {
  position: absolute;
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #82dbf7;
  color: #000;
  box-shadow: 0px 4px 12px rgba(169, 68, 66, 0.3),
    0px 2px 6px rgba(0, 0, 0, 0.1);
}


.duplicate-dataset-toast {
  z-index: 2000;
  position: absolute;
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 15px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fdecea;
  color: #a94442;
  border: 1px solid #f5c6cb;
  box-shadow: 0px 4px 12px rgba(169, 68, 66, 0.3),
    0px 2px 6px rgba(0, 0, 0, 0.1);
  bottom: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.duplicate-dataset-toast .action-btn {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #f5c6cb;
  color: #a94442;
  background-color: transparent;
  cursor: pointer;
}
.success-dataset-toast {
  z-index: 2000;
  position: absolute;
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 50%;
  left: 30%;
  border-radius: 15px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #e6f7e9; /* Light green background */
  color: #2c6e49; /* Dark green text */
  border: 1px solid #c3e6cb; /* Light green border */
  box-shadow: 0px 4px 12px rgba(44, 110, 73, 0.3),
    0px 2px 6px rgba(0, 0, 0, 0.1);
}

.coinsAnimation {
  height: 200px;
  width: 140px;
  position: absolute;
  left: 0;
  bottom: -30px;
}

.coinsCountText {
  font-size: 28px;
  font-weight: 800;
  line-height: 24px;
  color: #fff;
  margin-right: 10px;
}

.redeemContainer {
  position: absolute;
  right: 0;
  background: #f97403;
  width: 85px;
  height: 30px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.redeemText {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #1e2118;
}

.data-container-default-content {
  border-radius: 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  /* background: var(--Glass-Modal, rgba(26, 29, 33, 0.96)); */
  background: #0A1218;
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  display: flex;
  flex-direction: column;
  padding: 17px;
  position: relative;
  width: 100%;
}

.data-container-center-content {
  height: 100%;
  border-radius: 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: var(--Glass-Modal, rgba(26, 29, 33, 0.96));
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  display: flex;
  flex-direction: column;
  padding: 17px;
  position: relative;
  width: 100%;
  justify-content: center;
}

.problemTitleText {
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  color: var(--Neutral-100, #fff);
}

.problemSubTitleText {
  font-size: 28px;
  font-weight: 800;
  line-height: 24px;
  color: #82dbf7;
  margin-top: 8px;
  margin-bottom: 15px;
}

.data-img img {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.loaderStyles {
  height: 200px;
  width: 200px;

  margin: 0 auto;
}

.linkingText {
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  color: var(--Neutral-100, #fff);
}

.linkingSubText {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #8e8c91;
  margin-top: 8px;
}

.back-btn {
  width: 34px;
  height: 34px;
  background-color: #363a3d;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin-right: 6px;
}

.footer-proceed-btn-container {
  font-size: 15.529px;
  font-weight: 700;
  padding: 15px;
  width: 100%;
  height: 61px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-proceed-btn {
  width: 143px;
height: 34px;
border-radius: 12px;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
color: #000;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px; 
letter-spacing: 0.15px;
border: none;
cursor: pointer;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

.action-btn-wrapper {
  position: absolute;
  bottom: 50px;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  padding: 15px;
  width: 100%;
  height: 61px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.proceed-btn {
  height: 34px;
  padding: 6.8px 12.75px;
  border-radius: 10px;
  border: none;
  background: linear-gradient(91deg, #00ff85 7.38%, #00e5ff 96.85%);
  cursor: pointer;
  color: #000;
  font-size: 15.529px;
  font-weight: 700;
  margin-top: 15px;
}

.chat-tooltip {
  width: 100%;
  height: 75px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: var(
    --Glass-Fill,
    linear-gradient(
      118deg,
      rgba(215, 237, 237, 0.16) -47.79%,
      rgba(204, 235, 235, 0) 100%
    )
  );
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: relative;
  margin-top: 15px;
  padding-right: 10px;
}

.chatbodyContainer {
  height: 520px;
  border-radius: 6px 6px 16px 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: var(--Glass-Modal, rgba(26, 29, 33, 0.96));
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  position: relative;
  margin-top: -3px;
  padding: 10px;
}

.chatbody_collapse {
  width: 16px;
  height: 67px;
  position: absolute;
  top: 40%;
  left: -17px;
  cursor: pointer;
}

.coins-count-text {
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
  color: #fff;
  margin-right: 10px;
}
 .user-name-container{
  width: 59px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #1E4E40;
  opacity: 0.56;
  border-top-left-radius: 3px;
  border-top-right-radius: 15px;
}

.assistant-name-container{
  width: 41px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 3px;
  background: var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  align-self: flex-end;
  margin-right: 2px;
  opacity: 0.56;
}

.text-message-body {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  border-top-left-radius: 0px;
  padding: 15px;
}

.chat-input-container {
  width: 100%;
  height: 56px;
  border-radius: 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: var(--Glass-Modal, rgba(26, 29, 33, 0.96));
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
}

.response-loader {
  position: absolute;
  left: 10px;
  top: -32px;
  width: 170px;
  height: 31px;
  background: linear-gradient(
    113deg,
    rgba(0, 255, 255, 0.16) 0%,
    rgba(0, 255, 133, 0.16) 100%
  );
  border-top-right-radius: 2cqmin;
  clip-path: polygon(0 0, 92% 1%, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #01e5fd;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  align-items: center;
}

.chat-input {
  width: 271px;
  padding: 9.791px 11.749px;
  gap: 0.979px;
  border-radius: 3.916px;
  background: rgba(255, 255, 255, 0.06);
  color: #b8b8b8;
  font-size: 13px;
  font-weight: 500;
}
.chatIcon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chat-minimized-view {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  background-color: #00ff85;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.chatLoaderStyles {
  font-weight: 800;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -23px;
  right: 10;
}

.sampleProblemCard{
  flex: 0 1 210px;
    height: 100px;
    background: linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0));
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 6px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border: none;
    position: relative;
  }

  .sampledataset-subscribe-overflow{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Corrected opacity */
    backdrop-filter: blur(2px); /* Blurred effect */
    display: flex;
    color: #01FF85;
    font-size: 18px;
    font-weight: bold;
    z-index: 10; /* Ensures it is on top */
    border-radius: 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .sampleProblemCard:hover {
    transform: scale(1.1);
    border: 1px solid yellow,
}

.logout-btn-holder{
  position: absolute;
    bottom: 10px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.logout-btn-holder .logout-btn-styles{
  border-radius: 4px;
  background: rgba(249, 192, 3, 0.05);
  text-transform: none;
  color: #F9C003;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.15px;
  width: 149px;
  height: 34px;
  gap: 10px;
}

.problem-title-text{
  color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -1px;
  margin-bottom: 5px;
  word-spacing: 2px;
}

.problem-subtitle-text{
  color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: -1px;
}

.spring-effect {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1); /* Smooth easing */
}

.spring-effect.expanded {
  animation: spring 0.5s cubic-bezier(0.22, 1, 0.36, 1) forwards; /* Spring-like easing */
  opacity: 1;
}

.spring-effect.collapsed {
  animation: collapse 0.5s cubic-bezier(0.42, 0.05, 0.36, 1) forwards; /* Smooth collapse */
  opacity: 1;
}

@keyframes spring {
  0% {
    width: 80px;
    opacity: 0.8;
  }
  50% {
    width: 45%;
    opacity: 0.8;
  }
  100% {
    width: 40%;
    opacity: 1;
  }
}

@keyframes collapse {
  0% {
    width: 40%;
    opacity: 1;
  }
  50% {
    width: 60px;
    opacity: 0.9;
  }
  100% {
    width: 80px;
    opacity: 0.8;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  20%,
  80% {
    transform: translateX(-2px);
  }
  40%,
  60% {
    transform: translateX(2px);
  }
}

@media (max-width: 768px) {
  .data-container-default-content {
    padding: 0;
  }
  .data-container-center-content {
    padding: 0;
  }
  .progress-holder {
    width: 200px;
  }
}
