#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.iframe-container {
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow Swiper to detect drag */
}

.iframe-container iframe {
    width: 100%;
    height: 100%;
    pointer-events: auto; /* Enable interaction when clicked */
}

.swiper {
  width: 100%;
  padding-top: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 500px;
  /* height: 500px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-pagination-bullet {
    background: white !important; /* Change to any contrasting color */
    opacity: 0.5;
  }
  
  /* Active pagination bullet */
  .swiper-pagination-bullet-active {
    background: #12B981 !important; /* Example: Neon green for contrast */
    opacity: 1;
  }
