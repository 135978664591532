*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* z-index: 10; */
}

html{
  padding: 0;
  margin:0;
  overflow-x: hidden;
  background: #010E14;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
