.quiz-header {
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  padding-left: 80px;
  padding-right: 80px;
}

.quiz-header-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.quiz-progress-holder{
  display: flex;
  flex-direction: column;
}

.quiz-progress-text{
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 8px;
color: #FFF;
}

.quiz-footer-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
position: absolute;
left: 0;
bottom: 5%;
}

.try-again-toast{
  display: flex;
width: 114px;
height: 31px;
padding: 12px 16px;
align-items: center;
gap: 16px;
flex-shrink: 0;
border-radius: 8px;
background: #4A1214;
box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.14);
color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 13.618px; /* 113.483% */
letter-spacing: -0.284px;
margin-bottom: 25px;
}

.go-back-btn {
  width: 143px;
height: 34px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.10);
background: rgba(27, 30, 34, 0.3);
  color: #FFF;
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 150% */
letter-spacing: 0.15px;
margin-right: 15px;
cursor: pointer;
}

.quiz-proceed-btn{
  width: 143px;
height: 34px;
border-radius: 12px;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
color: #000;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px; 
letter-spacing: 0.15px;
border: none;
cursor: pointer;
}

.coins-details-container{
  width: 145px;
height: 45px;
flex-shrink: 0;
background-color: rgba(255, 134, 0, 0.1);
border-radius: 10px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding-left: 10px;
padding-right: 10px;
}

.point-instruction-text{
  color: #FF8600;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 13px; 
}

.point-count-text{
  color: #FF8600;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

.mcq-badge {
  width: 230px;
  height: 48px;
  background: #032927;
  stroke: var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  filter: drop-shadow(0px 24px 64px rgba(0, 0, 0, 0.24));
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #01fe84;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  clip-path: polygon(0 0, 100% 0, 91% 100%, 9% 100%);
}

.analysis-header {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 6px 6px 16px 16px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: #010c12;
  box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
    0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.analysis-header-title {
  color: var(--Neutral-100, #fff);
  text-align: left;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  letter-spacing: -1px;
  margin-bottom: 5px;
}

.analysis-header-subheader {
  color: #8e8c91;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.analysis-column-field-container {
  display: flex;
  flex-direction: column;
}

.analysis-column-field-item {
  width: auto;
  height: auto;
  border-radius: 10px;
  background: #1b1e22;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 10px;
  padding-right: 90px;
  margin-right: 10px;
  margin-bottom: 15px;
position:relative
}

.correct-answer-badge{
  position: absolute;
  top: 0;
  right: 0;
  width: 78px;
height: 13px;
border-radius: 20px;
color: #FFF;
background-color: #183831;
font-family: "Plus Jakarta Sans";
font-size: 8px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 225% */
letter-spacing: -0.5px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center ;
}

.rightAnswer{
  border: 1px solid #00FF85;
}
.wrongAnswer{
  border: 1px solid #852121;
}

.radio-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.radio-container input[type="radio"] {
  display: none;
}
.custom-radio {
  width: 18px;
  height: 18px;
  background-color: #363a3d; /* Default background color */
  border: 2px solid #ccc; /* Border for unselected state */
  border-radius: 50%; /* Circular shape */
  display: inline-block;
  position: relative;
  margin-right: 10px; /* Space between radio button and label */
  transition: background-color 0.3s, border-color 0.3s;
}

.radio-container:hover .custom-radio {
  border-color: #888; /* Change border color on hover */
}

.radio-container input[type="radio"]:checked + .custom-radio {
  background-color: rgba(0, 255, 133, 1); /* Fill color when selected */
  border-color: rgba(0, 255, 133, 1); /* Match border color to fill */
}


.radio-container input[type="radio"]:checked + .custom-radio::after {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #fff; /* Inner dot color */
  border-radius: 50%; /* Circular shape for inner dot */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the dot */
}


.checkbox-label {
  color: var(--Neutral-100, #fff);
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.5px;
}
.completion-container{
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

.completion-card{
  width: 445px;
  height: 445px;
  border-radius: 4px;
border: 1px solid rgba(255, 255, 255, 0.10);
background: #151c21;
margin-top: 60px;
display: flex;
flex-direction: column;
justify-content: start;
align-items: center;
padding: 40px 20px 20px 20px;
}

.completion-header-text{
  color: #00FF85;
font-family: "Plus Jakarta Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 150% */
letter-spacing: -0.5px;
margin-top: 25px;
}

.completion-subheader-text{
  color: #FFF;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 214.286% */
letter-spacing: -0.5px;
}

.choose-another-btn{
  width: 157px;
height: 44px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid rgba(255, 255, 255, 0.10);
background: #FFF;
color: #000;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 150% */
letter-spacing: 0.15px;
border: none;
margin-right: 15px;
}

.stay-on-same-btn{
  width: 151px;
height: 44px;
flex-shrink: 0;
border-radius: 12px;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
color: #000;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 150% */
letter-spacing: 0.15px;
border: none;
}
