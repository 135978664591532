@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sticky-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 50px 0px 50px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: linear-gradient(180deg, #010E14 72.83%, rgba(1, 12, 18, 0.00) 100%)
}

.card {
  animation: fadeIn 0.5s ease-in-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card {
  width: 199px;
  height: 230px;
  border-radius: 12px;
  border-radius: 12px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: var(--Glass-Fill,
      linear-gradient(118deg,
        rgba(215, 237, 237, 0.16) -47.79%,
        rgba(204, 235, 235, 0) 100%));
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.card img {
  width: 50.703px;
  height: 46px;
  margin-bottom: 15px;
}

.card h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.card-footer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.card-footer img {
  width: 30px;
  height: 14px;
  margin-bottom: 10px;
}

.home-image {
  margin-top: 10px;
  width: 200px;
  height: auto !important;
}

/* .highlight {
  border: 2px solid yellow;
  animation: blink 3s infinite;
} */

.intro-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.intro-modal-content {
  width: 100%;
  max-width: 1000px;
  background: #010c12;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
  padding: 15px 22px 15px 22px;
  color: var(--Neutral-100, #fff);
  border: 1px solid var(--Noble-Black-500, #363a3d);
}

.close-button {
  position: absolute;
  top: 0;
  right: 10px;
  background: transparent;
  border: none;
  color: #01ff85;
  font-size: 1.5rem;
  line-height: 1;
  padding: 10px 10px 0 10px;
  cursor: pointer;
  z-index: 10;
  font-size: 40px;
}

.close-button:hover {
  color: #01ff85;
  transform: scale(1.1);
}

.home-image {
  width: 200px;
  height: 100px;
  border-radius: 16px;
  object-fit: cover;
}

.text-number {
  font-size: 28px !important;
  font-weight: 800 !important;
  line-height: 24px !important;
  color: #fff !important;
  margin-right: 10px !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #01ff85;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: rgba(0, 0, 0, 0.8);
}

.custom-upload-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.try-now-card {
  width: 100%;
  height: 106px;
  border-radius: 12px;
  border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
  background: var(--Glass-Fill, linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0.00) 100%));
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.try-now-text {
  color: #FFF;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 112.5% */
  letter-spacing: -0.326px;
}

.upload-card {
  width: 100%;
  height: 390px;
  border: 1px solid #01ff85;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.upload-title {
  color: #FFF;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 112.5% */
  letter-spacing: -0.326px;
}

.paywall-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Corrected opacity */
  backdrop-filter: blur(2px); /* Blurred effect */
  display: flex;
  color: #01FF85;
  font-size: 18px;
  font-weight: bold;
  z-index: 10; /* Ensures it is on top */
  border-radius: 12px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@keyframes blink {
  0% {
    border-color: yellow;
  }

  20% {
    border-color: transparent;
  }

  50% {
    border-color: yellow;
  }

  80% {
    border-color: transparent;
  }

  100% {
    border-color: yellow;
  }
}

@media (max-width: 768px) {
  .menu-text {
    display: none;
  }

  .menu-image {
    display: none;
  }

  .menu-card {
    justify-content: center;
    align-items: center;
  }

  .home-image {
    display: none;
  }

  .redeem-box {
    display: none;
  }

  .total {
    display: none;
  }

  .coins-text {
    display: none;
  }
}

@media (max-width: 1024px) {
  .home-image {
    display: none;
  }
}