.typeJsWrapper {
    text-align: center;
    min-height: 68px;
}

.typeJsText {
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: -3.36px;
    background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.animatedText {
    display: inline-block;
    min-width: 130px; /* Adjust based on the max width of "Needed!" */
    text-align: left;
}
