.login-container{
    width: 100%;
    height: 100%;
    background-color: #D9D9D9;
    display: flex;
    flex-direction: row;
}

.login-form{
    width: 50%;
    background: #010C12;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
padding-left: 120px;
padding-right: 120px;
}

.login-form-overlay{
    width: 100%;
    height: 100%;
    border-radius: 500px;
opacity: 0.1;
background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
filter: blur(100px);
position: absolute;
top: -50%;
right: -50%;
}

.by {
    color: #fff;
font-family: "Plus Jakarta Sans";
font-size: 13.091px;
font-style: normal;
font-weight: 500;
line-height: 20.364px; /* 155.556% */
letter-spacing: 0.109px;
margin-right: 10px;
}
.description {
    color: var(--Noble-Black-300, #9B9C9E);
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.15px;
margin-top: 20px;
margin-bottom: 60px;
}

.input-container {
    position: relative;
    width: 250px;
  }

  .name{
    width: 496px;
    height: 48px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 35px;
    border-radius: 8px;
    border: 1px solid var(--gradients-blue-green-500, #82DBF7);
    background: var(--Noble-Black-600, #1A1D21);
    box-shadow: 0px 0px 0px 4px rgba(132, 220, 245, 0.24);
    color: var(--Noble-Black-0, #FFF);
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    background: var(--gradients-blue-green-500, linear-gradient(45deg, #82DBF7 0%, #B6F09C 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Body/L/Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .name::placeholder{
    color: var(--Noble-Black-300, #9B9C9E);
    /* Body/L/Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;  /* Adjusts the font size */
    /* padding-left: 10px; */ 
  }
  
  .email {
    width: 496px;
    height: 48px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 35px;
    border-radius: 8px;
    border: 1px solid var(--gradients-blue-green-500, #82DBF7);
    background: var(--Noble-Black-600, #1A1D21);
    box-shadow: 0px 0px 0px 4px rgba(132, 220, 245, 0.24);
    color: var(--Noble-Black-0, #FFF);
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    background: var(--gradients-blue-green-500, linear-gradient(45deg, #82DBF7 0%, #B6F09C 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Body/L/Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;

  }
  .email::placeholder {
    color: var(--Noble-Black-300, #9B9C9E);
    /* Body/L/Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;  /* Adjusts the font size */
    /* padding-left: 10px; */
  }
  
  .email-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }

  .submit-btn{
    display: flex;
    width: 496px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 12px;
    background: linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%);
    margin-top: 45px;
    cursor: pointer;
  }

  .login-image-contianer{
    background-image: url('https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/Illustration.png');
    background-size: cover;       /* Cover the entire div */
    background-position: center;  /* Center the image */
    width: 100%;                  /* Set width as desired */
    min-height: 100vh;   
  }

  .error-message {
    color: red;
    font-size: 0.85rem;
    margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  .login-form{
    padding-left: 40px;
    padding-right: 40px;
  }
  .email{
    width: 350px;
  }
  .submit-btn{
    width: 350px;
  }
}

@media screen and (max-width: 768px) {
  .login-form{
    padding-left: 20px;
    padding-right: 20px;
  }
  .email{
    width: 250px;
  }
  .submit-btn{
    width: 250px;
  }
}

@media screen and (max-width: 425px) {
  .login-form{
    padding-left: 10px;
    padding-right: 10px;
  }
  .email{
    width: 170px;
  }
  .submit-btn{
    width: 170px;
  }
}