.carousel-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.inner-carousel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
}

.content {
  width: 45%;
  flex: none;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.heroText {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.content .card-title {
  font-size: 1.2rem;
  letter-spacing: 0.4px;
  color: #01ff85;
  margin-bottom: 15px;
}

.mid-card-title {
  font-size: 1.2rem;
  letter-spacing: 0.4px;
  color: #01ff85;
  margin-bottom: 15px;
}

.content .card-text,
.content .feature-description {
  color: #8e8c91;
  font-size: 0.8rem;
  margin-top: 2px;
}

.mid-feature-description {
  color: #8e8c91;
  font-size: 0.8rem;
  margin-top: 2px;
}

.card-title {
  color: #01ff85;
  font-family: "Plus Jakarta Sans";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.5px;
}

.content .features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
}

.video-container {
  width: 45%; /* Matches the width of content */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.video-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.feature-card {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem;
  border-radius: 10px;
}

.feature-card .feature-icon {
  width: 1.7rem;
  height: 1.7rem;
}

.feature-text .feature-title {
  font-size: 0.9rem;
  color: #f5f5f5;
  font-weight: bold;
}

.mid-feature-title {
  font-size: 0.9rem;
  color: #f5f5f5;
  font-weight: bold;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background: #121212;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  width: 800px;
  max-height: 90%;
  overflow-y: none;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.first-section {
  height: 100%;
  padding: 20px;
}

.mid-section {
  height: 100%;
  padding: 20px;
}

.last-section {
  height: 100%;
  padding: 20px;
}

.first-card {
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: default;
}

.second-card {
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: default;
}

.third-card {
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: default;
}

.swiper-button-prev,
.swiper-button-next {
  color: #01ff85;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

@media (max-width: 576px) {
  .video-container {
    display: none;
  }
  .content {
    width: 100%;
  }
  .mid-card-title {
    font-size: small;
    margin-top: 0;
  }
  .mid-feature-title {
    font-size: xx-small;
    margin-top: 0;
  }
  .mid-feature-description {
    font-size: xx-small;
  }
}