
h1 {
  font-family: "Plus Jakarta Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: var(--font-weight-700, 700);
  line-height: 68px;
  letter-spacing: -2px;
}


strong {
  text-transform: capitalize;
}
